import React from 'react';
import { Link, graphql } from 'gatsby';

import Layout from '../../components/layout';
import SEO from '../../components/seo';
import { IPostNode } from '../../common/post';
import * as styles from './blog-post.module.scss';

interface IPostQueryData {
  markdownRemark: IPostNode;
}

interface IPageContext {
  fields: {
    slug: string;
  };
  frontmatter: {
    title: string;
  };
}

type BlogProps = {
  data: IPostQueryData;
  pageContext: {
    previous?: IPageContext;
    next?: IPageContext;
  };
};

const BlogPostTemplate: React.FC<BlogProps> = ({ data, pageContext }) => {
  const post = data.markdownRemark;
  const { previous, next } = pageContext;

  return (
    <Layout>
      <SEO title={post.frontmatter.title} description={post.frontmatter.description || post.excerpt} />
      <section className={styles.blogPost}>
        <div className={styles.blogHeader}>
          <h1 className={styles.title}>{post.frontmatter.title}</h1>
          <p className={styles.date}>{post.frontmatter.date}</p>
        </div>

        {/* eslint-disable-next-line react/no-danger */}
        <article className={styles.postBody} dangerouslySetInnerHTML={{ __html: post.html }} />
      </section>

      <section className={styles.blogPostBottom}>
        <h4>
          Follow me on Twitter
          <a href="https://mobile.twitter.com/NorbertBartos9" target="blank">
            Norbert Bartos
          </a>
        </h4>
      </section>

      <footer className={styles.footer}>
        <ul className={styles.navLinks}>
          <li>
            {previous && (
              <Link to={`blog${previous.fields.slug}`} rel="prev">
                {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={`blog${next.fields.slug}`} rel="next">
                {next.frontmatter.title}
              </Link>
            )}
          </li>
        </ul>
      </footer>
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`;
